import React, { useState, useLayoutEffect, useEffect } from 'react';
import { CButton, CFormSelect, CForm, CFormInput, CFormCheck } from '@coreui/react'
import Swal from "sweetalert2"
import axios from 'axios'
import ReactLoading from 'react-loading';
import Select from 'react-select'
import './InsertCambioFijo.css'

function InsertCambioFijo(props) {
  let [loading, setLoading] = useState(false)
  let [tipoPermiso, setTipoPermiso] = useState()
  let [recoge, setRecoge] = useState('')
  let [dia, setDia] = useState()
  let alumnos = props.alumnos
  let [rutas, setRutas] = useState([])
  let [ruta, setRuta] = useState()
  let [rutaResponse, setRutaResponse] = useState()
  let [alumno, setAlumno] = useState()
  let [deIda, setDeIda] = useState(false)
  let [deRegreso, setDeRegreso] = useState(false)
  let [direcciones, setDirecciones] = useState([])
  let [direccionDestino, setDireccionDestino] = useState('')
  let dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes"]

  useLayoutEffect(() => {
    let temp = []
    props.rutas.forEach((ruta) => { temp.push({ value: { ruta }, label: `${ruta.numero} ${ruta.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} ${ruta.direccionRuta}` }) })
    setRutas(temp.sort((a, b) => a.value.ruta.numero - b.value.ruta.numero));
  }, [])

  function handleSubmit() {

    if (tipoPermiso == 'direccionTemporal') {
      if (direccionDestino == '') {
        Swal.fire('Selecciona una direccion destino')
        return
      }
    }

    if (dia == undefined) {
      Swal.fire('Selecciona un dia')
      return
    }

    if (tipoPermiso == 'direccionTemporal') {

      if (deIda == false && deRegreso == false) {
        Swal.fire('Selecciona al menos un tipo de cambio')
        return
      }

      if (ruta == undefined) {
        Swal.fire('Selecciona una ruta')
        return
      }

    }

    if (tipoPermiso == 'papaRecoge') {
      if (recoge == '') {
        Swal.fire('Escribe el nombre de la persona que recoge')
        return
      }
    }


    setLoading(true)

    let data = {
      status: "Aceptado por escuela",
      tipo: tipoPermiso,
      alumnoInvitadoID: alumno._id,
      dia: dia,
      deIda: deIda,
      deRegreso: deRegreso,
      direccionNombre: direccionDestino.split('%')[0].trimEnd(),
      direccionDestino: direccionDestino.split('%')[1],
      idRutaDestino: ruta,
      personaRecoge: recoge,
    }

    axios.post(`${process.env.REACT_APP_BackURL}/insertCambioFijo`, data)
      .then((res) => {

        if (tipoPermiso == 'direccionTemporal') {

          if (deIda) {

            alumno.direccionesFijasIda[dia] = { dia: dias[dia], direccion: direccionDestino.split('%')[0].trimEnd() }
            axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasIda: alumno.direccionesFijasIda })
              .then((responseAlumno) => {
                Swal.fire('Cambio fijo creado con exito')
                  .then(() => {
                    res.data.data.idRutaDestino = rutaResponse
                    res.data.data.alumnoInvitadoID = responseAlumno.data.data
                    props.onSuccess(res.data.data)
                    setLoading(false)
                  })
                  .catch((err) => { console.log(err) })

              })
              .catch((err) => { console.log(err) })

          }

          if (deRegreso) {

            alumno.direccionesFijasRegreso[dia] = { dia: dias[dia], direccion: direccionDestino.split('%')[0].trimEnd() }
            axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasRegreso: alumno.direccionesFijasRegreso })
              .then((responseAlumno) => {
                Swal.fire('Cambio fijo creado con exito')
                  .then(() => {
                    res.data.data.idRutaDestino = rutaResponse
                    res.data.data.alumnoInvitadoID = responseAlumno.data.data
                    props.onSuccess(res.data.data)
                    setLoading(false)
                  })
                  .catch((err) => { console.log(err) })

              })
              .catch((err) => { console.log(err) })

          }

        }

        if (tipoPermiso == 'papaRecoge') {

          alumno.direccionesFijasRegreso[dia] = { dia: dias[dia], direccion: "papaRecoge" }
          axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, { direccionesFijasRegreso: alumno.direccionesFijasRegreso })
            .then((responseAlumno) => {
              Swal.fire('Cambio fijo creado con exito')
                .then(() => {
                  res.data.data.idRutaDestino = null
                  res.data.data.alumnoInvitadoID = responseAlumno.data.data
                  props.onSuccess(res.data.data)
                  setLoading(false)
                })
                .catch((err) => { console.log(err) })

            })
            .catch((err) => { console.log(err) })

        }

      })
      .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intetenta de nuevo por favor.') })

  }

  function getInfoAlumno(id) {
    axios.get(`${process.env.REACT_APP_BackURL}/getAlumno?id=${id}`)
      .then((res) => { setDirecciones(res.data.data.padres.filter((v) => !v.papaOmama)[0].secondary_contacts); setAlumno(res.data.data) })
      .catch((err) => { console.log(err) })
  }

  return (
    <>
      <br />

      <CForm>

        <center>

          <div className='col'>

            <h6>Escoge el tipo de cambio fijo</h6>
            <CFormSelect className='col' style={{ "maxWidth": "20vw" }} onChange={(e) => { setTipoPermiso(e.target.value) }}>
              <option>Tipo de cambio fijo</option>
              <option value="papaRecoge">Salida con padres</option>
              <option value="direccionTemporal">Direccion temporal</option>
            </CFormSelect>

            <br />

            <h6>Escoge el alumno para el cambio</h6>
            <Select placeholder="Selecciona..." className='inputSelect col' options={alumnos} onChange={(e) => { getInfoAlumno(e.value.alumno._id); setDireccionDestino(''); setDeIda(false); setDeRegreso(false); setRuta() }} />
          </div>

          <br />

          <h6>Escoge el dia de la semana</h6>
          <CFormSelect className='col' style={{ "maxWidth": "20vw" }} onChange={(e) => { setDia(e.target.value) }}>
            <option>Día de la semana</option>
            <option value="0">Domingo</option>
            <option value="1">Lunes</option>
            <option value="2">Martes</option>
            <option value="3">Miercoles</option>
            <option value="4">Jueves</option>
            <option value="5">Viernes</option>
          </CFormSelect>

          <br />

          {
            tipoPermiso != 'papaRecoge' ? null :
              <>
                <h6>Persona que lo recoge</h6>
                <CFormInput placeholder='Nombre' style={{ "maxWidth": "20vw" }} className='col' type="text" onChange={(e) => { setRecoge(e.target.value) }} /> <br />
              </>
          }

          {
            tipoPermiso != 'direccionTemporal' ? null :
              <>
                <h6>Escoge el tipo de cambio</h6>
                <CFormCheck checked={deIda} onChange={(e) => setDeIda(e.target.checked)} className='check' id="flexCheckDefault" label="De ida" />
                <CFormCheck checked={deRegreso} onChange={(e) => setDeRegreso(e.target.checked)} className='check' id="flexCheckDefault" label="De regreso" />

                <br />

                {
                  !direcciones != [] ? null :
                    <>
                      <h6>Escoge la direccion destino</h6>
                      <CFormSelect className='col' style={{ "maxWidth": "20vw" }} onChange={(e) => { setDireccionDestino(e.target.value) }}>
                        <option>Direcciones disponibles</option>
                        {
                          direcciones.map((direccion, i) => {
                            return (
                              <option key={i} value={`${direccion.contact_name}%${direccion.contact_address}`}>{direccion.contact_name} {direccion.contact_address}</option>
                            )
                          })
                        }
                      </CFormSelect>
                    </>
                }

                <h6>Asigna la ruta para el cambio</h6>
                <Select placeholder="Selecciona..." className='inputSelect col' options={rutas} onChange={(e) => { setRuta(e.value.ruta._id); setRutaResponse(e.value.ruta) }} />
              </>
          }

          <br />

        </center>

      </CForm>

      <div className='row'>
        <div className='col-sm-4 offset-md-4'>
          {
            loading ?

              <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>

              :

              tipoPermiso == "direccionTemporal" ?

                alumno == undefined || ruta == undefined ? null :
                  <>
                    <CButton style={{ 'width': '100%' }} color="info" onClick={() => { handleSubmit() }}>Crear cambio fijo</CButton>
                  </>
                :
                alumno == undefined || recoge == undefined ? null :
                  <>
                    <CButton style={{ 'width': '100%' }} color="info" onClick={() => { handleSubmit() }}>Crear cambio fijo</CButton>
                  </>
          }
        </div>
      </div>

      <br />
    </>
  );
}

export default InsertCambioFijo
