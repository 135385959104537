import React, { useState, useLayoutEffect } from 'react'
import { CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CFormInput, CModalFooter } from '@coreui/react'
import { TableVirtuoso } from 'react-virtuoso';
import axios from 'axios'
import ReactLoading from 'react-loading';
import Swal from "sweetalert2";
import './Notificaciones.css'

function Notificaciones() {
  let [loading, setLoading] = useState(true)
  let [rutas, setRutas] = useState([])
  let [visible1, setVisible1] = useState(false)
  let [idsNotificados, setIdsNotificados] = useState([])
  let [mensaje, setMensaje] = useState('')
  let [anuncios, setAnuncios] = useState([])

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_BackURL}/getTodayActiveHistorialRutas`)
      .then((response) => {
        let rutasFiltradas = response.data.data.filter((ruta) => ruta.alumnosAbordados.length > 0)
        setRutas(rutasFiltradas); setLoading(false)
      })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllAnuncios`)
      .then((response) => { setAnuncios(response.data.data) })
      .catch((err) => { console.log(err) })
  }, [])

  function notificarGeneral() {

    let ids = []

    rutas.forEach((ruta) => {
      if (ruta.alumnosAbordados.length > 0) {
        ruta.alumnosAbordados.forEach((alumno) => ids.push(alumno.id))
      }
    })

    axios.post(`${process.env.REACT_APP_BackURL}/insertAnuncio`, { fecha: new Date().toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }), anuncio: mensaje, idsNotificados: ids })
      .then((response) => {
        Swal.fire({ icon: 'success', title: 'Notificacion enviada', showConfirmButton: false, timer: 1500 })
        setAnuncios([...anuncios, response.data.data])
        setVisible1(false)
      })
      .catch((err) => { console.log(err) })
  }

  function notificarRuta() {
    axios.post(`${process.env.REACT_APP_BackURL}/insertAnuncio`, { fecha: new Date().toLocaleString('es-MX', { month: 'short', year: 'numeric', day: '2-digit' }), anuncio: mensaje, idsNotificados: idsNotificados })
      .then((response) => {
        Swal.fire({ icon: 'success', title: 'Notificacion enviada', showConfirmButton: false, timer: 1500 })
        setAnuncios([...anuncios, response.data.data])
        setVisible1(false)
      })
      .catch((err) => { console.log(err) })
  }

  return (
    <>

      <CModal visible={visible1} >

        <CModalHeader closeButton={false}>
          <CModalTitle>Enviar notificacion a App</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <center>
            {
              idsNotificados.length > 0 ?
                <h6>Se notificará a todos los alumnos abordados en la ruta</h6>
                :
                <h6>Se notificará a los papas de los alumnos abordados en las rutas que se muestran en pantalla pantalla</h6>
            }
            <br />
            <h6>Escribe el mensaje</h6>
            <CFormInput placeholder='Mensaje...' style={{ "maxWidth": "400px" }} className='col' type="text" onChange={(e) => { setMensaje(e.target.value) }} /> <br />
          </center>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible1(false)}>Cancelar</CButton>
          <CButton color="info" onClick={() => { idsNotificados.length > 0 ? notificarRuta() : notificarGeneral() }}>Enviar</CButton>
        </CModalFooter>

      </CModal>

      <div className='centrado'>
        <h3 className="margin">Enviar notificaciones</h3>
      </div>

      {
        !loading ?
          rutas.length == 0 ?
            <div className='centrado'><h1>No hay registros</h1></div>
            :
            <>

              <div className='centrado'>
                <CButton color="danger" onClick={() => {

                  let abordados = rutas.map((ruta) => ruta.alumnosAbordados.map((alumno) => alumno.id)).flat()

                  abordados.length > 0 ?
                    setVisible1(true)
                    :
                    Swal.fire({ icon: 'warning', title: 'No hay alumnos abordados en ninguna ruta', text: "No hay a quien notificar" });

                }}>Notificar a todas las rutas</CButton>
              </div>

              <br />

              <div className='fila'>

                <div className='col'>
                  <h6>Rutas con alumnos abordo</h6>
                  <TableVirtuoso
                    style={{ height: "55vh", width: '100%' }}
                    data={rutas}
                    components={{
                      Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                      TableHead: CTableHead,
                      TableRow: CTableRow,
                      TableBody: CTableBody
                    }}

                    fixedHeaderContent={() => (
                      <CTableRow color='info'>
                        <CTableHeaderCell className='cells'>Numero</CTableHeaderCell>
                        <CTableHeaderCell className='cells'>Notificar</CTableHeaderCell>
                      </CTableRow>
                    )}

                    itemContent={(index, item) => {
                      return (
                        <>
                          <CTableDataCell className='cells'>{item.rutaID.numero} {item.rutaID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                          <CTableDataCell className='cells'>
                            <CButton color='warning'
                              onClick={() => {

                                item.alumnosAbordados.map((alumno) => alumno.id).length > 0 ?
                                  setVisible1(true)
                                  :
                                  Swal.fire({ icon: 'warning', title: 'No hay alumnos abordados en esta ruta', text: "No hay a quien notificar" });

                                setIdsNotificados(item.alumnosAbordados.map((alumno) => alumno._id))
                              }}
                            >Notificar a esta ruta</CButton>
                          </CTableDataCell>
                        </>
                      )
                    }}
                  />
                </div>

                <div className='col'>
                  {
                    anuncios.length == 0 ?
                      <div className='centrado'><h1>No hay anuncios recientes</h1></div>
                      :
                      <>
                        <h6>Mesajes enviados en los ultimos 7 dias</h6>
                        <TableVirtuoso
                          style={{ height: "55vh", width: '100%' }}
                          data={anuncios}
                          components={{
                            Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(225,228,230)" }} color="light" hover />,
                            TableHead: CTableHead,
                            TableRow: CTableRow,
                            TableBody: CTableBody
                          }}

                          fixedHeaderContent={() => (
                            <CTableRow color='info'>
                              <CTableHeaderCell className='cells'>Fecha</CTableHeaderCell>
                              <CTableHeaderCell className='cells'>Mensaje</CTableHeaderCell>
                            </CTableRow>
                          )}

                          itemContent={(index, item) => {
                            return (
                              <>
                                <CTableDataCell className='cells'>{item.fecha}</CTableDataCell>
                                <CTableDataCell className='cells'>{item.anuncio}</CTableDataCell>
                              </>
                            )
                          }
                          }
                        />
                      </>
                  }
                </div>
              </div>

            </>
          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
      }

      <br />

    </>
  )
}

export default Notificaciones