import React, { useState, useLayoutEffect } from 'react'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CButton } from '@coreui/react'
import { TableVirtuoso } from 'react-virtuoso';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import ReactLoading from 'react-loading';
import dayjs from 'dayjs';
import './Reportes.css'

function Reportes(props) {
  let history = useHistory();
  let [loading, setLoading] = useState(true)
  let [reportesOG, setReportesOG] = useState([])
  let [reportes, setReportes] = useState([])
  let [tituloBoton1, setTituloBoton1] = useState("Filtrar por sección")

  function getReportes() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllReportes?archivo=${!props.archivo}`)
      .then((response) => { setReportes(response.data.data); setReportesOG(response.data.data); setLoading(false) })
      .catch((err) => { console.log(err) })
  }

  const filterData = (value) => {
    if (value) { setReportes(reportesOG.filter((item) => { return item.alumnoID.academic_section == value })) }
  };

  useLayoutEffect(() => {
    getReportes()
  }, [])

  return (
    <>

      <div className='centro'>

        <br />

        <div className='botones'>

          <CDropdown className='p-1'>

            <CDropdownToggle color="info">{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              <CDropdownItem onClick={() => { setReportes(reportesOG); setTituloBoton1("Sección") }} style={{ color: "green" }}>Todos</CDropdownItem>

              {
                [...new Set(reportesOG.map((item) => item.alumnoID.academic_section))].map((seccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filterData(seccion); setTituloBoton1(`${seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}`) }} className='siHay' >{seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CButton onClick={() => { history.push("/imprimir?tipo=reportes", { data: reportes }) }}>Imprimir</CButton>

          <h5>{reportes.length} reportes registrados</h5>

        </div>

        <br />

        {
          !loading ?
            reportes.length == 0 ?
              <center><h1>No hay reportes registrados</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "65vh", width: '100%' }}
                  data={reportes}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      {/* <CTableHeaderCell className='cells'>Status</CTableHeaderCell> */}
                      <CTableHeaderCell className='cells'>Fecha</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Alumnno</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Grado</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Reporte</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Atendido</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        {/* <CTableDataCell className='cells'>{item.status}</CTableDataCell> */}
                        <CTableDataCell className='cells'>{dayjs(item.fecha).format('DD/MM/YYYY')}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.alumnoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.alumnoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())} {item.alumnoID.group_code.split('-')[2]}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.reporte}</CTableDataCell>
                        <CTableDataCell className='cells'>
                          {
                            props.archivo ? <span style={{ color: "red" }}>Archivado</span> :
                              <CButton color='success' onClick={() => {
                                axios.patch(`${process.env.REACT_APP_BackURL}/reporteAtendidoById?id=${item._id}`)
                                  .then(() => { setReportes(reportes.filter((reporte) => reporte._id !== item._id)) })
                                  .catch((err) => { console.log(err) })
                              }}>Archivar</CButton>
                          }
                        </CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default Reportes
