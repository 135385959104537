import React, { useState } from 'react'
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import './Rutas.css'

function ImprimirCambios() {
  let history = useHistory();
  let permisosHoyOG = history.location.state
  let [permisosHoy, setPermisosHoy] = useState(history.location.state)
  let [tituloBoton1, setTituloBoton1] = useState("Filtrar por sección")

  const filterData = (value) => { if (value) { setPermisosHoy(permisosHoyOG.filter((item) => { return item.alumnoInvitadoID.academic_section == value })) } };

  return (
    <>
      <div className='centro'>

        <br />

        <div className='botones'>

          <CDropdown className='p-1'>

            <CDropdownToggle color="info">{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              <CDropdownItem onClick={() => { setPermisosHoy(permisosHoyOG); setTituloBoton1("Sección") }} style={{ color: "green" }}>Todos</CDropdownItem>

              {
                [...new Set(permisosHoyOG.map((item) => item.alumnoInvitadoID.academic_section))].map((seccion, i) => (

                  <CDropdownItem key={i} onClick={() => { filterData(seccion); setTituloBoton1(`${seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}`) }} className='siHay' >{seccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <CButton onClick={() => history.push("/imprimir?tipo=todos", { data: permisosHoy })}>Imprimir</CButton >
          <h5>{permisosHoy.length > 0 ? `${permisosHoy.length} cambio${permisosHoy.length == 1 ? '' : 's'} fijo${permisosHoy.length == 1 ? '' : 's'}` : 'Hoy no hay cambios para imprimir'}</h5>
        </div>

        <br />

        {
          <>

            <CTable style={{ width: '80%', margin: 'auto' }} responsive color="dark" hover>
              <CTableHead>
                <CTableRow color='info'>
                  <CTableHeaderCell>Nombre</CTableHeaderCell>
                  <CTableHeaderCell>Grado</CTableHeaderCell>
                  <CTableHeaderCell>Sección</CTableHeaderCell>
                  <CTableHeaderCell>Escuela</CTableHeaderCell>
                  <CTableHeaderCell>En ruta</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  permisosHoy.length > 0 ?
                    <>
                      {
                        permisosHoy.map((item, i) => {
                          return (
                            <CTableRow key={i}>
                              <CTableDataCell>{item.alumnoInvitadoID.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                              <CTableDataCell>{item.alumnoInvitadoID.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())} {item.alumnoInvitadoID.group_code.split('-')[2]}</CTableDataCell>
                              <CTableDataCell>{item.alumnoInvitadoID.academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                              <CTableDataCell>{item.alumnoInvitadoID.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                              <CTableDataCell>
                                {
                                  item.tipo == 'direccionTemporal' ?
                                    !item.idRutaDestino ? null :
                                      `${item.idRutaDestino.numero} ${item.idRutaDestino.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}`
                                    :
                                    "Recogen en el colegio"
                                }
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })
                      }
                    </>
                    :
                    <>
                      <CTableRow>
                        <CTableDataCell colSpan="8">Sin Resultados</CTableDataCell>
                      </CTableRow>
                    </>
                }

              </CTableBody>
            </CTable>
          </>
        }

        <br />

      </div>
    </>
  )
}

export default ImprimirCambios
