import React, { useState, useLayoutEffect } from 'react';
import { CButton, CTableHead, CTableRow, CTableBody, CTable, CTableHeaderCell, CTableDataCell, CForm, CFormInput, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import { TableVirtuoso } from 'react-virtuoso';
import { cilPlus } from '@coreui/icons';
import { useHistory } from 'react-router-dom'
import Swal from "sweetalert2"
import Select from 'react-select'
import CIcon from '@coreui/icons-react'
import ReactLoading from 'react-loading';
import axios from 'axios'
import './InsertRuta.css'

function InsertRuta() {
  let history = useHistory()
  let [loading, setLoading] = useState(false)
  let [alumnos, setAlumnos] = useState([])
  let [ruta, setRuta] = useState('')
  let [numero, setNumero] = useState('')
  let [alumno, setAlumno] = useState('')
  let [horario, setHorario] = useState('')
  let [direccionRuta, setDireccionRuta] = useState('Selecciona')
  let [alumnosRuta, setAlumnosRuta] = useState([])
  let [tituloSelect, setTituloSelect] = useState('Selecciona...')
  let [dias, setDias] = useState()

  useLayoutEffect(() => {
    let temp = []
    history.location.state.forEach((alumno) => { temp.push({ value: { alumno }, label: `${alumno.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())} / ${alumno.student_code}` }) })
    setAlumnos(temp); setLoading(false)
  }, [])

  function handleSubmit() {

    if (ruta == '') { Swal.fire('Ingresa un nombre de ruta'); return }

    if (numero == '') { Swal.fire('Ingresa un numero de ruta'); return }

    if (direccionRuta == 'Selecciona') { Swal.fire('Selecciona una direccion de ruta'); return }

    if (alumnosRuta.length == 0) { Swal.fire('Agrega por lo menos alumno a la ruta'); return }

    setLoading(true)

    let data = {
      nombre: ruta,
      numero: numero,
      direccionRuta: direccionRuta,
      alumnos: alumnosRuta,
      dias: direccionRuta == 'Ida' ? dias : null
    }

    axios.post(`${process.env.REACT_APP_BackURL}/insertRuta`, data)
      .then(() => {
        Swal.fire('Se creo la ruta con exito')
          .then(() => { window.location.href = "/rutas" })
        setLoading(false)
      })
      .catch((err) => { setLoading(false); Swal.fire('Ocurrio un error, intetenta de nuevo por favor.'); console.log(err) })
  }

  function agregarAlumno(alumno) {

    if (alumno == '') { Swal.fire('Selecciona un alumno'); return }

    if (horario == '') { Swal.fire('Ingresa un horario'); return }

    if (alumnosRuta.some((alumnoRuta) => alumnoRuta.id == alumno.id)) { Swal.fire('Este alumno ya esta en la ruta'); return }

    let nuevoAlumno = { horario: horario, id: alumno._id }

    setAlumnosRuta([...alumnosRuta, nuevoAlumno])

    setAlumno('')
    setHorario('')
    setTituloSelect('Selecciona...')
  }

  function alumnoInfo(id) {
    let alumno = alumnos.find((alumno) => String(alumno.value.alumno._id) == String(id))
    return alumno.value.alumno
  }

  return (
    <>

      <h3 style={{ 'textAlign': 'center' }}>Nueva ruta</h3>

      <br />

      <CForm>

        <div className='row'>

          <div className='col'>
            <CFormInput className='input col' label="Nombre de Ruta" placeholder='Tecamachalco' type="text" onChange={(e) => { setRuta(e.target.value) }} />
          </div>

          <div className='col'>
            <CFormInput className='input col' label="Numero de ruta" placeholder='# de ruta' type="text" onChange={(e) => { setNumero(e.target.value) }} />
          </div>

          <div className='col'>
            <h6>Direccion de ruta</h6>
            <CDropdown>
              <CDropdownToggle color="secondary">{direccionRuta}</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem onClick={() => setDireccionRuta("Ida")}>Ida</CDropdownItem>
                <CDropdownItem onClick={() => setDireccionRuta("Regreso")} >Regreso</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>

          {
            direccionRuta == 'Ida' ?
              <div className='col'>
                <h6>Dias de la semana</h6>
                <CDropdown>
                  <CDropdownToggle color="secondary">Selecciona</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => setDias("entreSemana")}>Lun a Vie</CDropdownItem>
                    <CDropdownItem onClick={() => setDias("finSemana")} >Domingo</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>
              : <></>
          }

        </div>

        <br />

        <div className='row'>

          <div className='col-6'>

            <h6>Insertar alumnos en la ruta</h6>

            {
              !loading ?
                <Select placeholder={tituloSelect} className='input col' options={alumnos} onChange={(e) => { setAlumno(e.value.alumno); setTituloSelect(e.value.alumno.nombre) }} />

                : <><div><ReactLoading type={'bubbles'} color={'#46eb34'} height={'2vw'} width={'2vw'} /></div></>
            }

          </div>


          <div className='col'>

            <CFormInput className='input col' value={horario} label="Horario" placeholder='Horario' type="time" onChange={(e) => { setHorario(e.target.value) }} />

          </div>

          <div className='col'>

            <br />

            <CButton shape='rounded-pill' color="success" onClick={() => { agregarAlumno(alumno) }}><CIcon icon={cilPlus} size="xl" /></CButton>

          </div>


        </div>

      </CForm>

      <br />

      {
        alumnosRuta.length == 0 ?

          <CTable responsive style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover>

            <CTableHead>
              <CTableRow color='info'>
                <CTableHeaderCell className='cells'>No.</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Horario</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Codigo de Alumno</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              <CTableRow>
                <CTableDataCell colSpan="8" className='cells'>No hay alumnos en la ruta</CTableDataCell>
              </CTableRow>
            </CTableBody>

          </CTable>

          :

          <TableVirtuoso
            style={{ height: "55vh", width: '100%', zIndex: 0 }}
            data={alumnosRuta}
            components={{
              Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
              TableHead: CTableHead,
              TableRow: CTableRow,
              TableBody: CTableBody
            }}

            fixedHeaderContent={() => (
              <CTableRow color='info'>
                <CTableHeaderCell className='cells'>No.</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Horario</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Codigo de Alumno</CTableHeaderCell>
                <CTableHeaderCell className='cells'>Eliminar</CTableHeaderCell>
              </CTableRow>
            )}

            itemContent={(index, item) => {
              return (
                <>
                  <CTableDataCell>{index + 1}</CTableDataCell>
                  <CTableDataCell className='cells'><input style={{ maxWidth: "70px" }} placeholder={item.horario} onChange={(e) => alumnosRuta[index].horario = e.target.value}></input></CTableDataCell>
                  <CTableDataCell className='cells'>{alumnoInfo(item.id).nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                  <CTableDataCell className='cells'>{alumnoInfo(item.id).student_code}</CTableDataCell>
                  <CTableDataCell className='cells'><CButton color="danger" onClick={() => { setAlumnosRuta(alumnosRuta.filter((alumnoRuta) => alumnoRuta.id != item.id)) }}>Eliminar</CButton></CTableDataCell>
                </>
              )
            }}
          />
      }
      <br />
      <div className='row'>

        <div className='col-sm-4 offset-md-4'>


          <div className='d-flex justify-content-evenly'>

            <CButton color="secondary" onClick={() => { window.location.href = "/rutas" }}>Cancelar</CButton>

            <CButton color="info" onClick={() => { handleSubmit() }}>Crear nueva ruta</CButton>

          </div>

        </div>

      </div>

      <br /> <br />

    </>
  );
}

export default InsertRuta
