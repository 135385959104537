import React, { useLayoutEffect, useState } from 'react';
import { CSpinner } from '@coreui/react';
import Swal from 'sweetalert2';
import axios from 'axios';
import dayjs from 'dayjs'
import './Dashboard.scss'
import './Dashboard.css'

const Dashboard = () => {
  let [totalAlumnos, setTotalAlumnos] = useState()
  let [alumnosSinRuta, setAlumnosSinRuta] = useState('...')
  let [totalPadres, setTotalPadres] = useState()
  let [totalFamilias, setTotalFamilias] = useState()
  let [familiasSinCamion, setFamiliasSinCamion] = useState('...')
  let [permisosPorAceptar, setPermisosPorAceptar] = useState('...')
  let [totalRutas, setTotalRutas] = useState()
  let [rutasActivas, setRutasActivas] = useState('...')
  let [totalPrefectas, setTotalPrefectas] = useState()
  let [reportes, setReportes] = useState('...')
  let [cambios, setCambios] = useState('...')
  let [papaRecoge, setPapaRecoge] = useState('...')
  let [force, setForce] = useState(0)
  let [permisosActivos, setPermisosActivos] = useState(false)
  let [id, setId] = useState()

  useLayoutEffect(() => {
    axios.get(`${process.env.REACT_APP_BackURL}/countAlumnos`)
      .then((response) => { setTotalAlumnos(response.data.data); })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/countPadres`)
      .then((response) => { setTotalPadres(response.data.data); })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/countFamilias`)
      .then((response) => { setTotalFamilias(response.data.data); setFamiliasSinCamion(response.data.sinCamion) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllPermisos`)
      .then((response) => { setPermisosPorAceptar(response.data.data.filter((permiso) => dayjs(permiso.fecha).isAfter(dayjs().subtract(1, 'day'))).filter((permiso) => permiso.status == "En espera de escuela").length) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/countRutas`)
      .then((response) => { setTotalRutas(response.data.data) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getActiveHistorialRutasByDate?dia=${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`)
      .then((response) => { setRutasActivas(response.data.data) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/countPrefectas`)
      .then((response) => { setTotalPrefectas(response.data.data) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/alumnosSinRuta?dashboard=true`)
      .then((response) => { setAlumnosSinRuta(response.data.data) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/countReportes`)
      .then((response) => { setReportes(response.data.data) })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getPermisosByDate?dia=${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}&tipo=cambios`)
      .then((response1) => {
        axios.get(`${process.env.REACT_APP_BackURL}/getPermisosByDate?dia=${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}&tipo=permisos`)
          .then((response2) => { setCambios(response1.data.data.length + response2.data.data.length); })
          .catch((err) => { console.log(err) })
      })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getPapaRecogeByDate?dia=${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`)
      .then((response) => {

        // esta funcion filtra los datos para que no se repitan, porque cuando los quito de las rutas, por cada ruta que lo quito se guarda en la base de datos que van a pasar por el, entonces se repiten
        let ids = []

        let sinRepetir = response.data.data.filter((item) => {
          let duplicados = ids.includes(item.alumnoInvitadoID._id);

          if (!duplicados) {
            ids.push(item.alumnoInvitadoID._id);
            return true;
          }
          return false;
        });
console.log(sinRepetir)
        setPapaRecoge(sinRepetir.length)


      })
      .catch((err) => { console.log(err) })

    axios.get(`${process.env.REACT_APP_BackURL}/getAllDiasHebreo`)
      .then((response) => { setPermisosActivos(response.data.data[0].status); setId(response.data.data[0]._id); })
      .catch((err) => { console.log(err) })

  }, [])

  return (
    <>
      <br />

      <center>

        <h3>Dashboard</h3>

        <div className='permisos'>

          <input type="checkbox" checked={permisosActivos ? true : false} onChange={(e) => {
            axios.patch(`${process.env.REACT_APP_BackURL}/updateDiasHebreo?id=${id}`, { status: e.target.checked, })
              .then((response) => { setForce(force + 1); setPermisosActivos(response.data.data.status) })
              .catch((err) => { Swal.fire({ icon: "error", title: "Error", text: "No se pudieron guardar los cambios" }); });
          }} />

          {
            permisosActivos ?
              <h6 style={{ color: 'green' }}>Permisos activos</h6>
              :
              <h6 style={{ color: 'red' }}>Permisos Inactivos</h6>
          }

        </div>

      </center>

      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/alumnos">

            <div className="card l-bg-cherry">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-user-graduate"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Alumnos registrados</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{totalAlumnos ? totalAlumnos : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/padres">

            <div className="card l-bg-red-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-user-group"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Padres registrados</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{totalPadres ? totalPadres : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/familias">
            <div className="card l-bg-blue-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-users" />
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Familias registradas</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{totalFamilias ? totalFamilias : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/prefectas">

            <div className="card l-bg-orange-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-person-dress"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Prefectas registradas</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">{totalPrefectas ? totalPrefectas : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/familias">
            <div className="card l-bg-yellow-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-bus" />
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Familias sin camion</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{familiasSinCamion == "..." ? <CSpinner /> : familiasSinCamion} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/rutas">

            <div className="card l-bg-aqua-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-user-graduate"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Alumnos sin ruta asignada</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">{alumnosSinRuta == '...' ? <CSpinner /> : alumnosSinRuta} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/rutas">
            <div className="card l-bg-purple-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-route"></i>{" "}
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Total de rutas</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">

                    <h2 className="d-flex align-items-center mb-0">
                      {totalRutas ? totalRutas : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/historialrutas">
            <div className="card l-bg-aqua-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-road"></i>{" "}
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Rutas Activas</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{rutasActivas == '...' ? <CSpinner /> : rutasActivas} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/permisos">

            <div className="card l-bg-green-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-file-contract"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Permisos por aceptar</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{permisosPorAceptar == '...' ? <CSpinner /> : permisosPorAceptar} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/papaRecogeHoy">
            <div className="card l-bg-blue-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-sheet-plastic"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Pasan por ellos hoy</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{papaRecoge != '...' ? papaRecoge : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/permisosHoy">

            <div className="card l-bg-cherry">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-sheet-plastic"></i>
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Cambios del día</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{cambios != '...' ? cambios : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

        <div className="col-xl-3 col-lg-6">
          <a className='noDecoration' href="/reportes">

            <div className="card l-bg-red-dark">
              <div className="card-statistic-3 p-4">
                <div className="card-icon card-icon-large">
                  <i className="fas fa-users" />
                </div>

                <div className="mb-4">
                  <h6 className="card-title mb-0">Reportes por atender</h6>
                </div>

                <div className="row align-items-center mb-2 d-flex">
                  <div className="col-8">
                    <h2 className="d-flex align-items-center mb-0">{reportes != '...' ? reportes : <CSpinner />} &nbsp;&nbsp;&nbsp;&nbsp;{" "}</h2>
                  </div>
                </div>

              </div>
            </div>
          </a>
        </div>

      </div>

      <br /><br />
    </>
  )
}

export default Dashboard
