import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CModal, CFormInput, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CModalHeader, CModalTitle, CModalBody, CModalFooter, CTable, CTableDataCell, CTableHeaderCell, CTableBody, CTableRow, CTableHead } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAddressBook } from '@coreui/icons';
import axios from 'axios'
import ReactLoading from 'react-loading';
import './Padres.css'
import { TableVirtuoso } from 'react-virtuoso';

function Padres() {
  let [loading, setLoading] = useState(true)
  let [visible1, setVisible1] = useState(false)
  let [padresOG, setPadresOG] = useState([])
  let [padres, setPadres] = useState([])
  let [alumnosDisplay, setAlumnosDisplay] = useState([])
  let [nombreDisplay, setNombreDisplay] = useState("")
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [tituloBoton1, setTituloBoton1] = useState("Servicio camión")
  let [searchText, setSearchText] = useState("")

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getPadres() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllPadresLight`)
      .then((response) => {

        axios.get(`${process.env.REACT_APP_BackURL}/getAllFamiliasLight`)
          .then((response2) => {

            let sinServicio = response2.data.data.filter((familia) => !familia.servicioCamion)

            if (response.data.data.length == 0) { setLoading(false) }
            else {

              for (let i in response.data.data) {

                if (sinServicio.some((familia) => familia.family_code == response.data.data[i].family_code)) {
                  response.data.data[i].servicioCamion = false
                }
                else {
                  response.data.data[i].servicioCamion = true
                }

                setPadres(response.data.data); setPadresOG(response.data.data); setLoading(false)

              }
            }
          })

          .catch((err) => { console.log(err) })

      })

      .catch((err) => { console.log(err) })
  }

  // filter records by search text and option
  const filterData = (value, filAcumulado) => {

    let temp = []

    let resultadoFiltrado = padresOG.filter((item) => {

      for (let key in filAcumulado) {
        if (item[key] === undefined || item[key] != filAcumulado[key])
          return false;
      }
      return true;
    });

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : "";

    if (lowercasedValue === "") { setPadres(temp) }
    else {
      const filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setPadres(filteredData);
    }
  };

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  function existe(key, value) {
    let siHay = false

    padres.forEach((alumno) => {
      if (alumno[key] == value) { siHay = true }
    })

    return siHay
  }

  function filtroActivo(obj, key) {
    return key in obj
  }

  function getAlumnos(familyCode) {
    axios.get(`${process.env.REACT_APP_BackURL}/getAlumnosByFamilyCode?family_code=${familyCode}`)
      .then((response) => {
        setAlumnosDisplay(response.data.data)
        setVisible1(true)
      })
      .catch((err) => { console.log(err) })
  }

  useLayoutEffect(() => {
    getPadres()
  }, [])

  useEffect(() => {
    filterData(searchText, filtroAcumulado)
  }, [searchText, filtroAcumulado])

  return (
    <>
      <div className='centro'>
        <CModal visible={visible1}>

          <CModalHeader closeButton={false}>
            <CModalTitle>Hijos de {nombreDisplay}</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CTable responsive color="dark" hover>

              <CTableHead>
                <CTableRow color='dark'>
                  <CTableHeaderCell>Nombre</CTableHeaderCell>
                  <CTableHeaderCell>Grado</CTableHeaderCell>
                  <CTableHeaderCell>Plantel</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              <CTableBody>
                {
                  alumnosDisplay.map((item, i) => {
                    return (
                      <CTableRow key={i} color="info">
                        <CTableDataCell>{item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell>{item.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell>{item.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                      </CTableRow>
                    )
                  })
                }
              </CTableBody>

            </CTable>
          </CModalBody>

          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          </CModalFooter>
        </CModal>

        <center>
          <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { setSearchText(e.target.value) })} />
        </center>

        <br />

        <div className='botones'>

          <CDropdown className='p-1'>

            <CDropdownToggle color={filtroActivo(filtroAcumulado, "servicioCamion") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>

            <CDropdownMenu className='dropdown-menu'>

              {
                !filtroActivo(filtroAcumulado, "servicioCamion") ? null :
                  <CDropdownItem onClick={() => { eliminarFiltroAcumulado("servicioCamion"); setTituloBoton1("Servicio camión") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
              }
              {
                [...new Set(padresOG.map((item) => item.servicioCamion))].map((servicioCamion, i) => (

                  <CDropdownItem key={i} onClick={() => { filtrarAcumulado("servicioCamion", servicioCamion); setTituloBoton1(`${servicioCamion ? "Si" : "No"} camión`) }} className={!existe("servicioCamion", servicioCamion) ? "noHay" : "siHay"}>{servicioCamion ? "Si" : "No"}</CDropdownItem>
                ))
              }

            </CDropdownMenu>

          </CDropdown>

          <h5>{padres.length} padres registrados</h5>

        </div>

        <br />

        {
          !loading ?
            padres.length == 0 ?
              <center><h1>No hay padres registradas</h1></center>
              :
              <>
                <TableVirtuoso
                  style={{ height: "55vh", width: '100%' }}
                  data={padres}
                  components={{
                    Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                    TableHead: CTableHead,
                    TableRow: CTableRow,
                    TableBody: CTableBody
                  }}

                  fixedHeaderContent={() => (
                    <CTableRow color='info'>
                      <CTableHeaderCell className='cells'>Codigo Familia</CTableHeaderCell>
                      <CTableHeaderCell className='cellsGrande'>Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='cellsGrande'>Email</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Telefono</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Servicio Camion</CTableHeaderCell>
                      <CTableHeaderCell className='cells'>Hijos</CTableHeaderCell>
                    </CTableRow>
                  )}

                  itemContent={(index, item) => {
                    return (
                      <>
                        <CTableDataCell className='cells'>{item.family_code}</CTableDataCell>
                        <CTableDataCell className='cellsGrande'>{item.father_name == '-' ? item.mother_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.father_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                        <CTableDataCell className='cellsGrande'>{item.mail}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.cell_phone}</CTableDataCell>
                        <CTableDataCell className='cells'>{item.servicioCamion ? "Si" : "No"}</CTableDataCell>
                        <CTableDataCell className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { setNombreDisplay(item.father_name == '-' ? item.mother_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.father_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())); getAlumnos(item.family_code); }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                      </>
                    )
                  }}
                />
              </>
            : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
        }

        <br />

      </div>
    </>
  )
}

export default Padres
