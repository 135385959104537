import React, { useState, useLayoutEffect, useEffect } from 'react'
import { CButton, CModal, CFormInput, CForm, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CModalHeader, CModalTitle, CModalBody, CModalFooter, CTable, CTableDataCell, CDropdownDivider, CTableHeaderCell, CTableBody, CTableRow, CTableHead, CFormLabel, CTableFoot } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAddressBook, cilArrowRight } from '@coreui/icons';
import axios from 'axios'
import ReactLoading from 'react-loading';
import './Alumnos.css'
import { TableVirtuoso } from 'react-virtuoso';

function Alumnos() {
  let [loading, setLoading] = useState(true)
  let [visible1, setVisible1] = useState(false)
  let [visible2, setVisible2] = useState(false)
  // let [visible3, setVisible3] = useState(false)
  let [alumnosOG, setAlumnosOG] = useState([])
  let [alumnos, setAlumnos] = useState([])
  let [alumnoDisplay, setAlumnoDisplay] = useState()
  let [padresDisplay, setPadresDisplay] = useState([])
  // let [editarAlumno, setEditarAlumno] = useState({}) // no se usa pero no se comentan
  let [filtroAcumulado, setFiltroAcumulado] = useState({})
  let [tituloBoton1, setTituloBoton1] = useState("Grado")
  let [tituloBoton2, setTituloBoton2] = useState("Servicio camión")
  let [tituloBoton3, setTituloBoton3] = useState("Escuela")
  let [tituloBoton4, setTituloBoton4] = useState("Sección")
  let [searchText, setSearchText] = useState("")

  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo donde se buscara

  function getAlumnos() {
    axios.get(`${process.env.REACT_APP_BackURL}/getAllAlumnosLight`)
      .then((response) => {

        axios.get(`${process.env.REACT_APP_BackURL}/getAllFamiliasLight`)
          .then((response2) => {

            let sinServicio = response2.data.data.filter((familia) => !familia.servicioCamion)

            if (response.data.data.length == 0) { setLoading(false) }
            else {

              for (let i in response.data.data) {

                if (sinServicio.some((familia) => familia.family_code == response.data.data[i].family_code)) {
                  response.data.data[i].servicioCamion = false
                }
                else { response.data.data[i].servicioCamion = true }

                setAlumnos(response.data.data); setAlumnosOG(response.data.data); setLoading(false)

              }
            }
          })

          .catch((err) => { console.log(err) })
      })

      .catch((err) => { console.log(err) })
  }

  // filter records by search text and option
  const filterData = (value, filAcumulado) => {
    let temp = []

    let resultadoFiltrado = alumnosOG.filter((item) => {
      for (let key in filAcumulado) {
        if (item[key] === undefined || item[key] != filAcumulado[key]) return false
      }

      return true
    })

    temp = resultadoFiltrado

    const lowercasedValue = value ? value.toLowerCase().trim() : ""

    if (lowercasedValue === "") { setAlumnos(temp) }
    else {
      const filteredData = temp.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        )
      })

      setAlumnos(filteredData)
    }
  }

  // function saveChange(alumno) {

  //   let alumnoOriginal = alumnosOG.find((index) => index._id === alumno._id) // busca el alumno original en el arreglo de alumnos OG

  //   let diferencias = Object.keys(alumno).filter((index) => alumno[index] !== alumnoOriginal[index])  // busca las diferencias entre el alumno original y el alumno editado y las guarda en un arreglo 

  //   let camposEditados = {}

  //   diferencias.forEach((index) => { camposEditados[index] = alumno[index] }) // crea un objeto con las diferencias  

  //   axios.patch(`${process.env.REACT_APP_BackURL}/updateAlumno?id=${alumno._id}`, alumno)
  //     .then(() => { window.location.reload() }) // aqui meter una alerta
  //     .catch((err) => { console.log(err) })
  // }

  function filtrarAcumulado(key, valor) {
    setFiltroAcumulado({ ...filtroAcumulado, [key]: valor })
  }

  function eliminarFiltroAcumulado(key) {
    let filtroAcumuladoTemp = { ...filtroAcumulado }
    delete filtroAcumuladoTemp[key]
    setFiltroAcumulado(filtroAcumuladoTemp)
  }

  function existe(key, value) {
    let siHay = false

    alumnos.forEach((alumno) => {
      if (alumno[key] == value) { siHay = true }
    })

    return siHay
  }

  function filtroActivo(obj, key) {
    return key in obj
  }

  function getAlumnoInfo (id){
    axios.get(`${process.env.REACT_APP_BackURL}/getAlumno?id=${id}`)
    .then((response) => {
      setAlumnoDisplay(response.data.data)
      setPadresDisplay(response.data.data.padres)
    })
    .catch((err) => { console.log(err) })
  }

  useLayoutEffect(() => {
    getAlumnos()
  }, [])

  useEffect(() => {
    filterData(searchText, filtroAcumulado)
  }, [searchText, filtroAcumulado])

  return (
    <>
      <CModal visible={visible1} size='xl' >

        <CModalHeader closeButton={false} >
          <CModalTitle>Direcciones de {!alumnoDisplay ? null : alumnoDisplay.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CModalTitle>

        </CModalHeader>

        <CModalBody>

          <div className='row'>

            <div className='col'>
              <h4>Ida</h4>
              <CTable responsive color="dark" hover>

                <CTableHead>
                  <CTableRow color='dark'>
                    <CTableHeaderCell>Dia</CTableHeaderCell>
                    <CTableHeaderCell>Destino</CTableHeaderCell>
                    <CTableHeaderCell>Parentesco</CTableHeaderCell>
                    <CTableHeaderCell>Direcciones</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  <>
                    {
                      !alumnoDisplay ? null :
                        alumnoDisplay.direccionesFijasIda.map((item, i) => {
                          return (
                            <>
                              <CTableRow color="info">
                                <CTableDataCell>{item.dia}</CTableDataCell>
                                <CTableDataCell>{item.direccion == "papaRecoge" ? "" : item.direccion}</CTableDataCell>
                                <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == "Papa").map(() => <CIcon icon={cilArrowRight} size="xxl" />) : item.direccion == "papaRecoge" ? <CIcon icon={cilArrowRight} size="xxl" /> : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccion).map((papa) => papa.relationship)}</CTableDataCell>
                                <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.direccion == "papaRecoge" ? "Pasan por el" : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name == item.direccion).map((papa) => papa.contact_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()))}</CTableDataCell>
                              </CTableRow>
                            </>
                          )
                        })
                    }
                  </>
                </CTableBody>

              </CTable>
            </div>

            <div className='col'>
              <h4>Regreso</h4>
              <CTable responsive color="dark" hover>

                <CTableHead>
                  <CTableRow color='dark'>
                    <CTableHeaderCell>Dia</CTableHeaderCell>
                    <CTableHeaderCell>Destino</CTableHeaderCell>
                    <CTableHeaderCell>Parentesco</CTableHeaderCell>
                    <CTableHeaderCell>Direcciones</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                <CTableBody>
                  <>
                    {
                      !alumnoDisplay ? null :
                        alumnoDisplay.direccionesFijasRegreso.map((item, i) => {
                          return (
                            <>
                              <CTableRow color="info">
                                <CTableDataCell>{item.dia}</CTableDataCell>
                                <CTableDataCell>{item.direccion == "papaRecoge" ? "" : item.direccion.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CTableDataCell>
                                <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == "Papa").map(() => <CIcon icon={cilArrowRight} size="xxl" />) : item.direccion == "papaRecoge" ? <CIcon icon={cilArrowRight} size="xxl" /> : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name.toLowerCase() === item.direccion.toLowerCase()).map((papa) => papa.relationship)}</CTableDataCell>
                                <CTableDataCell>{item.direccion == "Principal" ? alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : item.direccion == "papaRecoge" ? "Pasan por el" : alumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.filter((papa) => papa.contact_name.toLowerCase() === item.direccion.toLowerCase()).map((papa) => papa.contact_address)}</CTableDataCell>
                              </CTableRow>
                            </>
                          )
                        })
                    }
                  </>
                </CTableBody>

              </CTable>
            </div>

          </div>

        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible1(false)}>Cerrar</CButton>
          {/* <CButton color='success' onClick={() => { setVisible3(true) }}>Editar direcciones <CIcon icon={cilPencil} /></CButton> */}
        </CModalFooter>
      </CModal>

      <CModal visible={visible2} size='lg' >
        <CModalHeader closeButton={false}>
          <CModalTitle>Padres de {!alumnoDisplay ? null : alumnoDisplay.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <CTable responsive color="dark" hover>

            <CTableHead>
              <CTableRow color='dark'>
                <CTableHeaderCell>Nombre</CTableHeaderCell>
                <CTableHeaderCell>Telefono</CTableHeaderCell>
                <CTableHeaderCell>Correo</CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {
                padresDisplay != undefined ?
                  <>
                    {
                      padresDisplay.map((item, i) => {
                        if (item.papaOmama != undefined) {
                          return (
                            <CTableRow color='info' key={i}>
                              <CTableDataCell>{item.father_name != "-" ? item.father_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase()) : item.mother_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                              <CTableDataCell>{item.cell_phone}</CTableDataCell>
                              <CTableDataCell>{item.mail}</CTableDataCell>
                            </CTableRow>
                          )
                        }
                      })
                    }
                  </>

                  :
                  <>
                    <CTableRow key={1}>
                      <CTableDataCell colSpan={3}>Sin datos</CTableDataCell>
                    </CTableRow>
                  </>
              }
            </CTableBody>

          </CTable>
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible2(false)}>Cerrar</CButton>
        </CModalFooter>
      </CModal>

      {/* <CModal visible={visible3} size='m' onClick={() => setVisible3(false)}>
        <CModalHeader onClick={() => setVisible3(false)}>
          <CModalTitle>Editar direcciones de {editarAlumno.nombre}</CModalTitle>
        </CModalHeader>

        <CModalBody>

          <div className='row'>
            <div className='col'>
            <CForm>
            <CFormLabel>Direcciones de ida</CFormLabel>

            {
              editarAlumno.direccionesFijasIda ?
                editarAlumno.direccionesFijasIda.map((item, i) => {
                  return (
                    <div key={i}>
                      <CDropdown>
                        <CDropdownToggle color="info">Escoge direccion</CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem
                            id={"Principal"}
                            onClick={(e) => {
                              const updatedDireccionesFijas = [...editarAlumno.direccionesFijasIda]
                              updatedDireccionesFijas[i] = { ...updatedDireccionesFijas[i], direccion: e.target.id }
                              const updatedEditarAlumno = { ...editarAlumno, direccionesFijasIda: updatedDireccionesFijas }

                              setEditarAlumno(updatedEditarAlumno)
                            }}>
                            Principal - {AlumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CDropdownItem>
                          {
                            AlumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.map((papa) => (
                              <CDropdownItem
                                id={papa.contact_name}
                                onClick={(e) => {
                                  const updatedDireccionesFijas = [...editarAlumno.direccionesFijasIda]
                                  updatedDireccionesFijas[i] = { ...updatedDireccionesFijas[i], direccion: e.target.id }
                                  const updatedEditarAlumno = { ...editarAlumno, direccionesFijasIda: updatedDireccionesFijas }

                                  setEditarAlumno(updatedEditarAlumno)
                                }}>
                                {papa.contact_name} - {papa.contact_address}</CDropdownItem>
                            ))
                          }
                        </CDropdownMenu>
                      </CDropdown>

                      <br /><br />

                    </div>
                  );
                })

                : null
            }
          </CForm>
            </div>
            <div className='col'>
            <CForm>
            <CFormLabel>Direcciones de regreso</CFormLabel>

            {
              editarAlumno.direccionesFijasIda ?
                editarAlumno.direccionesFijasIda.map((item, i) => {
                  return (
                    <div key={i}>
                      <CDropdown>
                        <CDropdownToggle color="info">Escoge direccion</CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem
                            id={"Principal"}
                            onClick={(e) => {
                              const updatedDireccionesFijas = [...editarAlumno.direccionesFijasIda]
                              updatedDireccionesFijas[i] = { ...updatedDireccionesFijas[i], direccion: e.target.id }
                              const updatedEditarAlumno = { ...editarAlumno, direccionesFijasIda: updatedDireccionesFijas }

                              setEditarAlumno(updatedEditarAlumno)
                            }}>
                            Principal - {AlumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].principal_address.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</CDropdownItem>
                          {
                            AlumnoDisplay.padres.filter((papa) => papa.papaOmama == undefined)[0].secondary_contacts.map((papa) => (
                              <CDropdownItem
                                id={papa.contact_name}
                                onClick={(e) => {
                                  const updatedDireccionesFijas = [...editarAlumno.direccionesFijasIda]
                                  updatedDireccionesFijas[i] = { ...updatedDireccionesFijas[i], direccion: e.target.id }
                                  const updatedEditarAlumno = { ...editarAlumno, direccionesFijasIda: updatedDireccionesFijas }

                                  setEditarAlumno(updatedEditarAlumno)
                                }}>
                                {papa.contact_name} - {papa.contact_address}</CDropdownItem>
                            ))
                          }
                        </CDropdownMenu>
                      </CDropdown>

                      <br /><br />

                    </div>
                  );
                })

                : null
            }
          </CForm>
              </div>
          </div>
         


        </CModalBody>

        <CModalFooter>
          <CButton color="danger" onClick={() => setVisible3(false)}>Cancelar</CButton>

          <CButton color="success" onClick={() => { saveChange(editarAlumno) }}>Guardar cambios</CButton>
        </CModalFooter>
      </CModal> */}

      <center>
        <CFormInput style={{ "width": "30%" }} type="text" id="floatingInput" label="Buscador" placeholder='Buscar' onChange={((e) => { setSearchText(e.target.value) })} />
      </center>

      <br />

      <div className='botones'>

        <CDropdown className='p-1'>
          <CDropdownToggle color={filtroActivo(filtroAcumulado, "campus_name") ? "info" : "secondary"}>{tituloBoton3}</CDropdownToggle>

          <CDropdownMenu className='dropdown-menu'>
            {
              !filtroActivo(filtroAcumulado, "campus_name") ? null :
                <CDropdownItem onClick={() => { eliminarFiltroAcumulado("campus_name"); setTituloBoton3("Escuela") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
            }

            {
              [...new Set(alumnosOG.map((item) => item.campus_name))].map((campus_name, i) => (

                <CDropdownItem key={i} onClick={() => { filtrarAcumulado("campus_name", campus_name); setTituloBoton3(`Escuela ${campus_name}`) }} className={!existe("campus_name", campus_name) ? "noHay" : "siHay"}>{campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
              ))
            }
          </CDropdownMenu>
        </CDropdown>

        <CDropdown className='p-1'>
          <CDropdownToggle color={filtroActivo(filtroAcumulado, "academic_section") ? "info" : "secondary"}>{tituloBoton4}</CDropdownToggle>

          <CDropdownMenu className='dropdown-menu'>
            {
              !filtroActivo(filtroAcumulado, "academic_section") ? null :
                <CDropdownItem onClick={() => { eliminarFiltroAcumulado("academic_section"); setTituloBoton4("Sección") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
            }

            {
              [...new Set(alumnosOG.map((item) => item.academic_section))].map((academic_section, i) => (

                <CDropdownItem key={i} onClick={() => { filtrarAcumulado("academic_section", academic_section); setTituloBoton4(`Sección ${academic_section}`) }} className={!existe("academic_section", academic_section) ? "noHay" : "siHay"}>{academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
              ))
            }
          </CDropdownMenu>
        </CDropdown>

        <CDropdown className='p-1'>
          <CDropdownToggle color={filtroActivo(filtroAcumulado, "degree") ? "info" : "secondary"}>{tituloBoton1}</CDropdownToggle>

          <CDropdownMenu className='dropdown-menu'>
            {
              !filtroActivo(filtroAcumulado, "degree") ? null :
                <>
                  <CDropdownItem onClick={() => { eliminarFiltroAcumulado("degree"); setTituloBoton1("Grados") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
                  <CDropdownDivider />
                </>
            }

            {
              [...new Set(alumnosOG.map((item) => item.degree))].map((degree, i) => (
                <CDropdownItem key={i} onClick={() => { filtrarAcumulado("degree", degree); setTituloBoton1(`Grado ${degree}`) }} className={!existe("degree", degree) ? "noHay" : "siHay"}>{degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CDropdownItem>
              ))
            }
          </CDropdownMenu>
        </CDropdown>

        <CDropdown className='p-1'>
          <CDropdownToggle color={filtroActivo(filtroAcumulado, "servicioCamion") ? "info" : "secondary"}>{tituloBoton2}</CDropdownToggle>

          <CDropdownMenu className='dropdown-menu'>
            {
              !filtroActivo(filtroAcumulado, "servicioCamion") ? null :
                <CDropdownItem onClick={() => { eliminarFiltroAcumulado("servicioCamion"); setTituloBoton2("Servicio camión") }} style={{ color: "red" }}>Eliminar filtro</CDropdownItem>
            }

            {
              [...new Set(alumnosOG.map((item) => item.servicioCamion))].map((servicioCamion, i) => (

                <CDropdownItem key={i} onClick={() => { filtrarAcumulado("servicioCamion", servicioCamion); setTituloBoton2(`${servicioCamion ? "Si" : "No"} camión`) }} className={!existe("servicioCamion", servicioCamion) ? "noHay" : "siHay"}>{servicioCamion ? "Si" : "No"}</CDropdownItem>
              ))
            }
          </CDropdownMenu>
        </CDropdown>

        <CButton color='danger' onClick={() => { setFiltroAcumulado({}) }}>Reset filtros</CButton>

        <h5>{alumnos.length} alumnos registrados</h5>

      </div >

      <br />

      {
        !loading ?
          alumnos.length == 0 ?
            <center><h1>No hay alumnos registrados</h1></center>
            :
            <TableVirtuoso
              style={{ height: "55vh", width: '100%' }}
              data={alumnos}
              components={{
                Table: ({ style, ...props }) => <CTable {...props} style={{ backgroundColor: "rgb(82,93,113)" }} color="dark" hover />,
                TableHead: CTableHead,
                TableRow: CTableRow,
                TableBody: CTableBody
              }}

              fixedHeaderContent={() => (
                <CTableRow color='info'>
                  <CTableHeaderCell className='cells'>Nombre</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Codigo de Familia</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Codigo de Alumno</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Grado</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Sección</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Escuela</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Servicio Camion</CTableHeaderCell>
                  <CTableHeaderCell className='cells'>Direcciones</CTableHeaderCell>
                  {/* <CTableHeaderCell className='cells'>Direcciones Regreso</CTableHeaderCell> */}
                  <CTableHeaderCell className='cells'>Padres</CTableHeaderCell>
                </CTableRow>
              )}

              itemContent={(index, item) => {
                return (
                  <>
                    <CTableDataCell className='cells'>{item.nombre.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                    <CTableDataCell className='cells'>{item.family_code}</CTableDataCell>
                    <CTableDataCell className='cells'>{item.student_code}</CTableDataCell>
                    <CTableDataCell className='cells'>{item.degree.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                    <CTableDataCell className='cells'>{item.academic_section.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                    <CTableDataCell className='cells'>{item.campus_name.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toLowerCase().toUpperCase())}</CTableDataCell>
                    <CTableDataCell className='cells'>{item.servicioCamion ? "Si" : "No"}</CTableDataCell>
                    <CTableDataCell className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { setVisible1(true); getAlumnoInfo(item.id) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                    <CTableDataCell className='cells'><CButton color='info' shape="rounded-pill" onClick={() => { setVisible2(true); getAlumnoInfo(item.id) }}><CIcon icon={cilAddressBook} size="xl" /></CButton></CTableDataCell>
                  </>
                )
              }}
            />

          : <><div className='loading'><ReactLoading type={'spin'} color={'cornflowerblue'} height={'5vw'} width={'5vw'} /></div></>
      }

      <br />

    </>
  )
}

export default Alumnos
